import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSection from '../Section/DepartmentSection';
import Spacing from '../Spacing';
import List from '../List';
import AppointmentSection2 from "../Section/AppointmentSection/index2";

const departmentData = [
    {
        title: 'Graphic Designer',
        iconUrl: '/images/home_1/services_01.svg',
        href: '/graphic-designer',
    },
    {
        title: 'Web Development',
        iconUrl: '/images/home_1/services_02.svg',
        href: '/web-developments',
    },
    {
        title: 'Mobile Application',
        iconUrl: '/images/home_1/services_03.svg',
        href: '/mobile-application',
    },
    {
        title: 'Database Architecture',
        iconUrl: '/images/home_1/services_04.svg',
        href: '/database-architecture',
    },
    {
        title: 'Project Design',
        iconUrl: '/images/home_1/services_05.svg',
        href: '/project-design',
    },
    {
        title: 'Server Configuration',
        iconUrl: '/images/home_1/services_06.svg',
        href: '/server-configuration',
    },
];

export default function GetQuote() {
    pageTitle('Get Quote');


    return (
        <>


            <Section
                topMd={190}
                topLg={145}
                topXl={105}
                bottomMd={190}
                bottomLg={145}
                bottomXl={110}
                id="appointment"
            >
                <AppointmentSection2

                    imgUrl="/images/home_1/appointment.jpeg"
                />
            </Section>
        </>
    );
}