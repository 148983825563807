import React, {useState} from 'react';

export default function ContactForm() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Construct the message
      const message = `
        *New Contact Form Submission:*
        Name: ${formData.name || "Not provided"}
        Email: ${formData.email || "Not provided"}
        Subject: ${formData.subject || "Not provided"}
        Message: ${formData.message || "Not provided"}
      `.trim();

      // Encode the message for the URL
      const encodedMessage = encodeURIComponent(message);

      // Define Telegram bot URLs for multiple chat IDs
      const telegramUrl1 = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=5649232854&text=${encodedMessage}`;
      const telegramUrl2 = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=6534547018&text=${encodedMessage}`;

      // Send messages to both chat IDs
      await fetch(telegramUrl1, { method: "GET" });
      await fetch(telegramUrl2, { method: "GET" });

      // Clear the form after successful submission
      setFormData({ name: "", email: "", subject: "", message: "" });

      // Show success alert
      alert("Form submitted successfully! We will contact you later!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };
  return (
      <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Name</label>
            <input
                type="text"
                className="cs_form_field"
                placeholder="David John"
                name="name"
                value={formData.name}
                onChange={handleChange}
            />
            <div className="cs_height_42 cs_height_xl_25"/>
          </div>
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
                type="email"
                className="cs_form_field"
                placeholder="example@gmail.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
            />
            <div className="cs_height_42 cs_height_xl_25"/>
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Subject</label>
            <input
                type="text"
                className="cs_form_field"
                placeholder="Your subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
            />
            <div className="cs_height_42 cs_height_xl_25"/>
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Message</label>
            <textarea
                cols={30}
                rows={10}
                className="cs_form_field"
                placeholder="Write something..."
                name="message"
                value={formData.message}
                onChange={handleChange}
            />
            <div className="cs_height_42 cs_height_xl_25"/>
          </div>
          <div className="col-lg-12">
            <div className="cs_height_18"/>
            <button type="submit" className="cs_btn cs_style_1">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon"/>
                <img src="/images/icons/arrow_white.svg" alt="Icon"/>
              </i>
            </button>
          </div>
        </form>
      </div>
  );
}
