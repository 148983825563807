import React, {useState} from 'react';

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const [formData, setFormData] = useState({
    email: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const message = `
      *News letters added Request:*
      Email: ${formData.email || 'Not provided'}
 
    `.trim();

      // Encode the message for the URL
      const encodedMessage = encodeURIComponent(message);

      // Replace YOUR_BOT_USERNAME with your bot's username
      const telegramUrl = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=5649232854&text=${encodedMessage}`;
      const telegramUrl2 = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=6534547018&text=${encodedMessage}`;

      await fetch(telegramUrl)
      await fetch(telegramUrl2)

      setFormData({ email: ''  }); // Clear the form


    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form.');
    }
  };


  return (
    <>
      {label && <p>Your Email</p>}
      <form onSubmit={handleSubmit} className="cs_newsletter_form">
        <input
          type="text"
          className="cs_form_field"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="example@email.com"
        />
        <button type="submit" className="cs_btn cs_style_1">
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
