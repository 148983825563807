import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSection from "../Section/DepartmentSection";
import DoctorDetailsSection from "../Section/DoctorDetailsSection";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import GraphicDesignerDetails from "./graphic-designer-details";
import WebDevelopmentsDetails from "./web-developments-details";


const departmentData = [
  {
    title: 'Graphic designer',
    iconUrl: '/images/home_1/services_01.svg',
    href: '/graphic-designer',
  },
  {
    title: 'Web development',
    iconUrl: '/images/home_1/services_02.svg',
    href: '/web-developments',
  },
  {
    title: 'Mobile application',
    iconUrl: '/images/home_1/services_03.svg',
    href: '/mobile-application',
  }, {
    title: 'Database Architecture',
    iconUrl: '/images/home_1/services_04.svg',
    href: '/database-architecture',
  },
  {
    title: 'Project design',
    iconUrl: '/images/home_1/services_05.svg',
    href: '/project-design',
  },
  {
    title: 'Server configuration and maintenance',
    iconUrl: '/images/home_1/services_06.svg',
    href: '/server-configuration',
  },
];
export default function WebDevelopments() {
  pageTitle('Web Developments');
  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={0} bottomLg={100} bottomXl={110}>
        <WebDevelopmentsDetails
            bgUrl="/images/doctors/doctor_details_bg.svg"
            imgUrl="/images/departments/web-dev.webp"
            name="Web Development "
            department="Department"
             description="In today’s digital age, having a robust and user-friendly website is essential for businesses of all sizes. At Screen Craft Solutions, our Web Development team specializes in creating custom websites that not only meet your business needs but also exceed your expectations. From concept to deployment, we ensure every step of the development process aligns with your goals and vision."

            degreesHeading="Custom Website Design"
            ecommerceHeading="E-Commerce Solutions"
            ecommerce={[
                { title: 'Build secure and scalable online stores with integrated payment gateways.'},
                { title: 'Optimize checkout processes to improve conversion rates.'},
            ]}
            ArchitecturesHeading="Scalable Architectures"
            Architectures={[
                { title: 'Develop modular systems that grow with your business. '},
                { title: 'Use cloud-based technologies to ensure scalability and reliability.'},
            ]}
            SEOFriendlyHeading="SEO-Friendly Development"
            SEOFriendly={[
                { title: 'Implement clean code structures and semantic HTML for better SEO.' },
                { title: 'Optimize meta tags, alt texts, and page load speeds.' },
                { title: 'Ensure mobile-first design for improved search rankings.' },
                { title: 'Integrate with analytics tools for tracking performance.' },
            ]}
            Securityheading="Security and Performance"
            Securityfeatures={[
                { title: 'Implement SSL encryption and secure authentication mechanisms.' },
                { title: 'Regularly update and patch vulnerabilities in your system.' },
                { title: 'Optimize website speed using caching, minification, and compression.' },
                { title: 'Monitor server health and performance with real-time alerts.' },
            ]}
            IntegrationHeading="Integration with Third-Party Tools"
            IntegrationFeatures={[
                { title: 'Seamlessly integrate with CRM systems like Salesforce and HubSpot.' },
                { title: 'Connect with email marketing platforms such as Mailchimp and Sendinblue.' },
                { title: 'Leverage analytics tools like Google Analytics and Hotjar for insights.' },
                { title: 'Integrate social media APIs for enhanced user engagement.' },
            ]}
            AgileHeading="Agile Development Process"
            AgileFeatures={[
                { title: 'Follow an iterative approach to deliver high-quality results quickly.' },
                { title: 'Involve clients throughout the development process for transparency.' },
                { title: 'Break projects into manageable sprints with regular feedback loops.' },
                { title: 'Deliver incremental updates and improvements based on client feedback.' },
            ]}
            CustomHeading="Custom Website Design"
            CustomFeatures={[
                { title: 'Tailored solutions designed specifically for your brand and audience.' },
                { title: 'Responsive design ensures optimal performance across all devices.' },
                { title: 'Modern UI/UX design principles for engaging user experiences.' },
                { title: 'Brand consistency across all pages and elements.' },
            ]}
        />
      </Section>


      <Section topMd={50} topLg={100} topXl={110}>
        <DepartmentSection
            sectionTitle="Our departments"
            sectionTitleUp="MORE TYPE OF"
            bgUrl="images/home_1/department_bg.svg"
            data={departmentData}
        />
      </Section>
      {/* Start Appointment Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection

          imgUrl="/images/appointments/Our-Questions.png"
        />
      </Section>

    </>
  );
}
