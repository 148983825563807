import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSection from "../Section/DepartmentSection";
import DoctorDetailsSection from "../Section/DoctorDetailsSection";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import GraphicDesignerDetails from "./graphic-designer-details";


const departmentData = [
  {
    title: 'Graphic designer',
    iconUrl: '/images/home_1/services_01.svg',
    href: '/graphic-designer',
  },
  {
    title: 'Web development',
    iconUrl: '/images/home_1/services_02.svg',
    href: '/web-developments',
  },
  {
    title: 'Mobile application',
    iconUrl: '/images/home_1/services_03.svg',
    href: '/mobile-application',
  }, {
    title: 'Database Architecture',
    iconUrl: '/images/home_1/services_04.svg',
    href: '/database-architecture',
  },
  {
    title: 'Project design',
    iconUrl: '/images/home_1/services_05.svg',
    href: '/project-design',
  },
  {
    title: 'Server configuration and maintenance',
    iconUrl: '/images/home_1/services_06.svg',
    href: '/server-configuration',
  },
];
export default function GraphicDesigner() {
  pageTitle('Graphic Designer');
  return (
    <>
      <BreadcrumbStyle2 />
      <Section bottomMd={0} bottomLg={100} bottomXl={110}>
        <GraphicDesignerDetails
            bgUrl="/images/doctors/doctor_details_bg.svg"
            imgUrl="/images/departments/graphic-designing.avif"
            name="Graphic Designer "
            department="Department"
             description="We are seeking a talented and creative Graphic Designer to join our dynamic team at Screen Craft Solutions. The ideal candidate will be responsible for creating visually appealing designs that effectively communicate our brand identity and messaging across various platforms. This role requires a strong understanding of design principles, attention to detail, and the ability to translate concepts into engaging visual content."


             degrees={[
              {
                title: 'Develop high-quality graphic designs for digital and print media, including logos, brochures, advertisements, social media posts, infographics, presentations, and more..',
               },
              {
                title:
                    'Collaborate with cross-functional teams (marketing, product, sales) to understand project requirements and deliver designs that align with business objectives.\n',
               },
              {
                title: 'Conceptualize ideas and create unique designs that resonate with target audiences while maintaining brand consistency.',
              },
              {
                title: 'Ensure all designs adhere to brand guidelines and standards.',
              },
              {
                title: 'Stay updated on industry trends and incorporate innovative design techniques into projects.',
              },
              {
                title: 'Optimize graphics for web, mobile, and other digital platforms to ensure compatibility and performance.',
              },
              {
                title: 'Provide revisions and iterate on designs based on feedback from stakeholders.',
              },
              {
                title: 'Manage multiple projects simultaneously, meeting deadlines and prioritizing tasks effectively.',
              },
            ]}
            degreesHeading="Key Responsibilities"
            experiences={[
              { title: ' Proven experience as a Graphic Designer, preferably in an agency or corporate environment.' },
              { title: '  Strong portfolio showcasing a range of creative work, including branding, UI/UX design, and marketing materials.' },
              { title: '  Proficiency in design software such as Adobe Photoshop, Illustrator, InDesign, and Sketch/Figma.' },
              { title: '   Excellent knowledge of color theory, typography, layout, and composition.' },
              { title: '   Ability to think creatively and solve complex design challenges.' },
              { title: '    Strong communication skills to articulate design choices and collaborate with team members.' },
              { title: '  Attention to detail and the ability to produce pixel-perfect designs.' },
              { title: '  Familiarity with responsive design principles and user experience best practices is a plus.' },
              { title: '  Degree in Graphic Design, Visual Arts, or a related field (or equivalent experience).' },

            ]}
            experiencesHeading="Requirements"
            awards={[

                { title: ' Experience with motion graphics or animation tools like After Effects.' },
                { title: ' Basic understanding of front-end development technologies (HTML, CSS).' },
                { title: ' Knowledge of SEO principles for visual content optimization.' },
                { title: ' Experience working in agile environments.' },
            ]}
            awardHeading="Preferred Skills"

            offers={[

                 { title: '  A collaborative and innovative work environment where creativity thrives.' },
                 { title: '      Opportunities for professional growth and skill development.' },
                 { title: '  Competitive salary and benefits package.' },
                 { title: '  Flexible working hours and remote work options (if applicable).' },
                 { title: '  Access to cutting-edge tools and resources to enhance your design capabilities.' },
            ]}
            offersHeading="What We Offer"
        />
      </Section>


      <Section topMd={50} topLg={100} topXl={110}>
        <DepartmentSection
            sectionTitle="Our departments"
            sectionTitleUp="MORE TYPE OF"
            bgUrl="images/home_1/department_bg.svg"
            data={departmentData}
        />
      </Section>
      {/* Start Appointment Section */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>

    </>
  );
}
