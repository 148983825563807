import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import Spacing from '../Spacing';

import { pageTitle } from '../../helpers/PageTitle';

export default function BlogDetails() {
  const { blogId } = useParams(); // Get the project ID from the URL
  const [project, setProject] = useState(null);

  // Fetch project details from an API
  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(`https://api.screencraftsolutions.com/blogdetails/${blogId}`); // Replace with your API endpoint
        if (!response.ok) throw new Error('Failed to fetch project details.');

         var result = await response.json();
        result.data.images = JSON.parse(result.data.images)
         setProject(result.data);
        pageTitle(result.data.title || 'Project Details'); // Set the page title dynamically
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    };

    fetchProjectDetails();
  }, [blogId]);

  if (!project) {
    return <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title= "Project Details"/>
      </Section>
    </>;
  }

  return (
      <>
        <Section topMd={170} bottomMd={54} bottomLg={54}>
          <Breadcrumb title={project.title} />
        </Section>
        <div className="container">
          <div className="cs_project_details_info">
            <div className="cs_project_details_info_left">
              <div className="cs_project_details_date">
                {new Date(project.date).toLocaleDateString()} | {project.author}
              </div>
            </div>
          </div>
          <Spacing md="55" />
          <img
              src={project.thumb ? `https://api.screencraftsolutions.com/storage/${project.thumb}` : '/default-project.jpg'}
              alt={project.title}
              className="  cs_radius_20"
          />
          <Spacing md="90" lg="50" />
          <div className="row">
            <div className="col-lg-12">
              <div className="cs_project_details">
                <h2>{project.title}</h2>
                <p>{project.shortdesc}</p>
                <p dangerouslySetInnerHTML={{ __html: project.text }}></p>

                {/* Display Additional Images */}
                {project.images && project.images.length > 0 && (
                    <div>
                      <h4>Related Images:</h4>
                      <div className="row">
                        {project.images.map((image, index) => (
                            <div key={index} className="col-md-4">
                              <img
                                  src={`https://api.screencraftsolutions.com/storage/${image}`}
                                  alt={`${project.title} Image ${index + 1}`}
                                  className="img-fluid"
                                  style={{ maxWidth: '100%', height: 'auto' }}
                              />
                            </div>
                        ))}
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>

    </>
  );
}
