import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';

import { pageTitle } from '../../helpers/PageTitle';
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import FeaturesSectionStyle4 from "../Section/FeaturesSection/FeaturesSectionStyle4";
const departmentData = [

    {
        title: "Graphic designer",
        iconUrl: "images/home_1/services_01.svg",
        subTitle: "We turn your ideas into reality with thoughtful color choices and creative design.",
        href: "/graphic-designer"
    },
    {
        title: "Web & Mobile Development",
        iconUrl: "images/home_1/services_02.svg",
        subTitle: "Build accessible, user-friendly web and mobile platforms for your business.",
        href: "/mobile-application"
    },
    {
        title: "Architect database",
        iconUrl: "images/home_1/services_04.svg",
        subTitle: "Master database management for fast, secure, and reliable data storage.",
        href: "/database-architecture"
    },
    {
        title: "Project design",
        iconUrl: "images/home_1/services_05.svg",
        subTitle: "Successful projects thrive on precise designs and adaptable strategies, ensuring longevity and effectiveness.",
        href: "/project-design"
    },
    {
        title: "Server configuration and maintenance",
        iconUrl: "images/home_1/services_06.svg",
        subTitle: "Install, configure, and maintain servers for optimal network performance.",
        href: "/server-configuration"
    }


];
const awardData = [
    {
        title: 'Swift ',
        iconUrl: 'images/brands/Apple.svg',
    },
    {
        title: 'Android',
        iconUrl: 'images/brands/android.png',
    },
    {
        title: 'TIZEN TV',
        iconUrl: 'images/brands/Tizen_Logo.png',
    },
    {
        title: 'LG WebOS',
        iconUrl: 'images/brands/lgwebos.png',
    },
    {
        title: 'Angular JS',
        iconUrl: 'images/brands/angular.png',
    },
    {
        title: 'React JS',
        iconUrl: 'images/brands/react.png',
    },
    {
        title: 'Vue JS',
        iconUrl: 'images/brands/vuejs.png',
    },

    {
        title: 'NodeJS',
        iconUrl: 'images/brands/nodejs.webp',
    },

    {
        title: 'Svelte',
        iconUrl: 'images/brands/svelete.png',
    },

    {
        title: 'Laravel',
        iconUrl: 'images/brands/Laravel.png',
    },


    {
        title: 'Mysql / Mango DB',
        iconUrl: 'images/brands/database.png',
    },

    {
        title: 'Ubuntu Server',
        iconUrl: 'images/brands/ubuntu.png',
    },

];

const featureListData = [
  {
    title: 'Seasoned Development <br /> Experts',
    subTitle:
      ' Our team comprises experienced developers and engineers who are dedicated to delivering top-notch solutions tailored to your needs',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Comprehensive Services',
    subTitle:
      ' We offer a full range of development services, from web and app development to complex system integrations and support.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Client-Centric  Approach',
    subTitle:
      'We prioritize understanding your unique business requirements and work closely with you to create custom solutions that align with your goals.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Cutting-Edge   Technology',
    subTitle:
      'Our agency uses the latest technologies and tools to ensure that our solutions are innovative, efficient, and future-proof.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: '7+', title: 'Years of development expertise' },
  { number: '100%', title: 'Client satisfaction rating' },
  { number: '15+', title: 'Projects completed annually' },
  { number: '150+', title: 'Successful deployments' },
  { number: '30+', title: 'Industries served' },
];
const featureList  = [
    {
        iconUrl: 'images/home_1/compassion.svg',
        title: 'Innovation',
        subTitle:
            'Embracing new ideas and pushing boundaries to drive progress.',
    },
    {
        iconUrl: 'images/home_1/excellence.svg',
        title: 'Excellence',
        subTitle:
            'Striving for the highest quality and continuous improvement',
    },
    {
        iconUrl: 'images/home_1/integrity.svg',
        title: 'Integrity',
        subTitle: `Upholding honesty, transparency, and ethical practices in all we do`,
    },
    {
        iconUrl: 'images/home_1/respect.svg',
        title: 'Services',
        subTitle:
            'Prioritizing client needs and delivering exceptional value.',
    },
    {
        iconUrl: 'images/home_1/teamwork.svg',
        title: 'Collaboration',
        subTitle:
            'Working together to achieve common goals and foster teamwork.',
    },
];




export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/about.png"
        title="Welcome to Screen Craft Solutions "
        subTitle="Elevating Your Digital Experience with Expert Development."
      />

        <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
            <FeaturesSectionStyle2
                sectionTitle="Why Choose Us"
                imgUrl="images/about/software-services-420x660.webp"
                data={featureListData}
            />
        </Section>
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
        {/* Start Award Section */}



        <Section
            topMd={50}
            topLg={50}
            topXl={50}
            bottomMd={50}
            bottomLg={50}
            bottomXl={50}
        >
            <AwardSectionStyle2
                sectionTitle="Working great with  <br />top platforms "
                sectionTitleUp=""
                sectionSubTitle=""
                data={awardData}
            />
        </Section>
        {/* End Award Section */}

        <Section topMd={200} topLg={150} topXl={110}>
            <FeaturesSectionStyle4
                sectionTitle="Our Values"
                data={featureList}
            />
        </Section>
      <Section topMd={200} topLg={150} topXl={110}>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>


    </>
  );
}
