import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSection from '../Section/DepartmentSection';
import Spacing from '../Spacing';
import List from '../List';

const departmentData = [
    {
        title: 'Graphic Designer',
        iconUrl: '/images/home_1/services_01.svg',
        href: '/graphic-designer',
    },
    {
        title: 'Web Development',
        iconUrl: '/images/home_1/services_02.svg',
        href: '/web-developments',
    },
    {
        title: 'Mobile Application',
        iconUrl: '/images/home_1/services_03.svg',
        href: '/mobile-application',
    },
    {
        title: 'Database Architecture',
        iconUrl: '/images/home_1/services_04.svg',
        href: '/database-architecture',
    },
    {
        title: 'Project Design',
        iconUrl: '/images/home_1/services_05.svg',
        href: '/project-design',
    },
    {
        title: 'Server Configuration',
        iconUrl: '/images/home_1/services_06.svg',
        href: '/server-configuration',
    },
];

export default function ProjectDesign() {
    pageTitle('Project Design');

    const bgUrl = "/images/doctors/doctor_details_bg.svg";
    const imgUrl = "/images/departments/project-design.webp";
    const name = "Project Design";
    const description =
        "Effective project design is the foundation of successful digital solutions. At Screen Craft Solutions, our Project Design team specializes in creating comprehensive strategies and blueprints that align with your business goals, ensuring seamless execution and delivery.";

    const featuresHeading = "Key Features";
    const features = [
        { title: "Comprehensive Requirement Analysis" },
        { title: "Customized Solution Architectures" },
        { title: "Wireframing and Prototyping" },
        { title: "UI/UX Design Integration" },
        { title: "Agile Project Planning" },
    ];

    const methodologiesHeading = "Methodologies We Use";
    const methodologies = [
        { title: "Waterfall for Structured Projects" },
        { title: "Agile for Iterative Development" },
        { title: "Scrum for Collaborative Teams" },
        { title: "Lean for Resource Optimization" },
    ];

    const collaborationHeading = "Collaboration and Communication";
    const collaborationFeatures = [
        { title: "Regular Stakeholder Meetings" },
        { title: "Transparent Progress Reports" },
        { title: "Real-Time Feedback Loops" },
        { title: "Dedicated Project Managers" },
    ];

    const toolsHeading = "Tools We Use";
    const tools = [
        { title: "Jira for Task Management" },
        { title: "Figma for UI/UX Design" },
        { title: "Trello for Agile Boards" },
        { title: "Confluence for Documentation" },
    ];

    return (
        <>
            <BreadcrumbStyle2 />
            <Section bottomMd={0} bottomLg={100} bottomXl={110}>
                <div className="cs_doctor_details">
                    <div
                        className="cs_doctor_details_bg cs_bg_filed"
                        style={{
                            backgroundImage: `url(${bgUrl})`,
                        }}
                    />
                    <Spacing md="85" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="cs_single_doctor overflow-hidden cs_radius_20">
                                    <img src={imgUrl} alt="Project Design" className="w-100" />
                                </div>
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={featuresHeading}
                                    iconUrl="/images/icons/features.svg"
                                    data={features}
                                />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={methodologiesHeading}
                                    iconUrl="/images/icons/award2.svg"
                                    data={methodologies}
                                />
                            </div>
                            <div className="col-lg-6 offset-lg-1 position-relative">
                                <Spacing md="55" />
                                <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>
                                <Spacing md="32" />
                                <p className="mb-0 cs_heading_color">{description}</p>
                                <Spacing md="200" xl="150" lg="80" />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={collaborationHeading}
                                    iconUrl="/images/icons/collaboration.svg"
                                    data={collaborationFeatures}
                                />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={toolsHeading}
                                    iconUrl="/images/icons/tools.svg"
                                    data={tools}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <Section topMd={50} topLg={100} topXl={110}>
                <DepartmentSection
                    sectionTitle="Our Departments"
                    sectionTitleUp="MORE TYPE OF"
                    bgUrl="images/home_1/department_bg.svg"
                    data={departmentData}
                />
            </Section>

            {/* Start Appointment Section */}
            <Section
                topMd={190}
                topLg={145}
                topXl={105}
                bottomMd={190}
                bottomLg={145}
                bottomXl={110}
                id="appointment"
            >
                <AppointmentSection
                    sectionTitle="Consultation"
                    sectionTitleUp="SCHEDULE A"
                    imgUrl="/images/home_1/appointment.jpeg"
                />
            </Section>
        </>
    );
}