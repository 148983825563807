 import React from 'react';
 import List from '../List';

import Spacing from '../Spacing';

export default function GraphicDesignerDetails({
  bgUrl,
  imgUrl,
  name,
   description,
  degrees,
  degreesHeading,
  experiences,
  experiencesHeading,
  awards,
  awardHeading,
  offers,
    offersHeading,

}) {
  return (
    <div className="cs_doctor_details">
      <div
        className="cs_doctor_details_bg cs_bg_filed"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
      />
      <Spacing md="85" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cs_single_doctor overflow-hidden cs_radius_20">
              <img src={imgUrl} alt="Doctor" className="w-100" />

            </div>
            <Spacing md="70" lg="50" />
            <List
                heading={awardHeading}
                iconUrl="/images/icons/award2.svg"
                data={awards}
            />
            <Spacing md="70" lg="50" />
            <List
                heading={offersHeading}
                iconUrl="/images/icons/graduation.svg"
                data={offers}
            />
          </div>
          <div className="col-lg-6 offset-lg-1 position-relative">
            <Spacing md="55" />
            <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>

            <Spacing md="32" />
            <p className="mb-0 cs_heading_color">{description}</p>

            <Spacing md="200" xl="150" lg="80" />
            <Spacing md="35" lg="0" />
            <List
              heading={degreesHeading}
              iconUrl="/images/icons/Responsibilities.svg"
              data={degrees}
            />
            <Spacing md="70" lg="50" />
            <List
              heading={experiencesHeading}
              iconUrl="/images/icons/requirements.svg"
              data={experiences}
            />

          </div>
        </div>
      </div>
    </div>
  );
}
