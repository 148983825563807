 import React from 'react';
 import List from '../List';

import Spacing from '../Spacing';

export default function WebDevelopmentsDetails({
  bgUrl,
  imgUrl,
  name,
   description,
    ecommerce, Architectures, SEOFriendly, Securityfeatures, IntegrationFeatures, AgileFeatures,IntegrationHeading,
    Securityheading, CustomHeading,  SEOFriendlyHeading,  AgileHeading,   CustomFeatures,ecommerceHeading, ArchitecturesHeading
}) {
  return (
    <div className="cs_doctor_details">
      <div
        className="cs_doctor_details_bg cs_bg_filed"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
      />
      <Spacing md="85" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cs_single_doctor overflow-hidden cs_radius_20">
              <img src={imgUrl} alt="Doctor" className="w-100" />

            </div>
            <Spacing md="70" lg="50" />
            <List
                heading={ecommerceHeading}
                iconUrl="/images/icons/ecommerce.svg"
                data={ecommerce}
            />
            <Spacing md="70" lg="50" />

            <List
                heading={AgileHeading}
                iconUrl="/images/icons/agile-dev.svg"
                data={AgileFeatures}
            />
            <Spacing md="70" lg="50" />

            <List
                heading={CustomHeading}
                iconUrl="/images/icons/web-design.svg"
                data={CustomFeatures}
            />
          </div>
          <div className="col-lg-6 offset-lg-1 position-relative">
            <Spacing md="55" />
            <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>

            <Spacing md="32" />
            <p className="mb-0 cs_heading_color">{description}</p>

            <Spacing md="200" xl="150" lg="80" />
            <Spacing md="70" lg="50" />

            <List
                heading={ArchitecturesHeading}
                iconUrl="/images/icons/information-architecture.svg"
                data={Architectures}
            />
            <Spacing md="70" lg="50" />

            <List
                heading={SEOFriendlyHeading}
                iconUrl="/images/icons/user-seo.svg"
                data={SEOFriendly}
            />
            <Spacing md="70" lg="50" />

            <List
                heading={Securityheading}
                iconUrl="/images/icons/security.svg"
                data={Securityfeatures}
            />
            <Spacing md="70" lg="50" />

            <List
                heading={IntegrationHeading}
                iconUrl="/images/icons/license-third-party.svg"
                data={IntegrationFeatures}
            />


          </div>
        </div>
      </div>
    </div>
  );
}
