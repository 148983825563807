import React from 'react';
import BannerSectionStyle7 from '../Section/BannerSection/BannerSectionStyle7';
import Section from '../Section';
import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from '../Breadcrumb/BreadcrumbStyle2';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import DepartmentSection from '../Section/DepartmentSection';
import Spacing from '../Spacing';
import List from '../List';

const departmentData = [
    {
        title: 'Graphic Designer',
        iconUrl: '/images/home_1/services_01.svg',
        href: '/graphic-designer',
    },
    {
        title: 'Web Development',
        iconUrl: '/images/home_1/services_02.svg',
        href: '/web-developments',
    },
    {
        title: 'Mobile Application',
        iconUrl: '/images/home_1/services_03.svg',
        href: '/mobile-application',
    },
    {
        title: 'Database Architecture',
        iconUrl: '/images/home_1/services_04.svg',
        href: '/database-architecture',
    },
    {
        title: 'Project Design',
        iconUrl: '/images/home_1/services_05.svg',
        href: '/project-design',
    },
    {
        title: 'Server Configuration',
        iconUrl: '/images/home_1/services_06.svg',
        href: '/server-configuration',
    },
];

export default function DatabaseArchitecture() {
    pageTitle('Database Architecture');

    const bgUrl = "/images/doctors/doctor_details_bg.svg";
    const imgUrl = "/images/departments/database.webp";
    const name = "Database Architecture";
    const department = "Department";
    const description =
        "In today's data-driven world, having a robust and well-architected database system is critical for businesses. At Screen Craft Solutions, our Database Architecture team specializes in designing, implementing, and managing scalable, secure, and high-performance databases tailored to meet your business needs.";

    const featuresHeading = "Key Features";
    const features = [
        { title: "Custom Database Design" },
        { title: "Scalable Architectures" },
        { title: "Data Security and Compliance" },
        { title: "High Availability and Fault Tolerance" },
        { title: "Performance Optimization" },
    ];

    const technologiesHeading = "Technologies We Use";
    const technologies = [
        { title: "MySQL for Relational Databases" },
        { title: "PostgreSQL for Advanced SQL Features" },
        { title: "MongoDB for NoSQL Solutions" },
        { title: "AWS RDS for Cloud-Based Databases" },
        { title: "Microsoft Azure SQL for Enterprise Needs" },
    ];

    const securityHeading = "Security and Compliance";
    const securityFeatures = [
        { title: "End-to-End Encryption" },
        { title: "Role-Based Access Control (RBAC)" },
        { title: "Regular Security Audits" },
        { title: "Compliance with GDPR, HIPAA, and PCI-DSS" },
    ];

    const agileHeading = "Agile Development Process";
    const agileFeatures = [
        { title: "Iterative Design and Implementation" },
        { title: "Frequent Client Feedback Loops" },
        { title: "Transparent Project Management" },
        { title: "Deliver Incremental Updates Based on Client Input" },
    ];

    return (
        <>
            <BreadcrumbStyle2 />
            <Section bottomMd={0} bottomLg={100} bottomXl={110}>
                <div className="cs_doctor_details">
                    <div
                        className="cs_doctor_details_bg cs_bg_filed"
                        style={{
                            backgroundImage: `url(${bgUrl})`,
                        }}
                    />
                    <Spacing md="85" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="cs_single_doctor overflow-hidden cs_radius_20">
                                    <img src={imgUrl} alt="Database Architecture" className="w-100" />
                                </div>
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={featuresHeading}
                                    iconUrl="/images/icons/features.svg"
                                    data={features}
                                />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={technologiesHeading}
                                    iconUrl="/images/icons/technology.svg"
                                    data={technologies}
                                />
                            </div>
                            <div className="col-lg-6 offset-lg-1 position-relative">
                                <Spacing md="55" />
                                <h2 className="cs_fs_48 mb-0 cs_semibold">{name}</h2>
                                <Spacing md="32" />
                                <p className="mb-0 cs_heading_color">{description}</p>
                                <Spacing md="200" xl="150" lg="80" />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={securityHeading}
                                    iconUrl="/images/icons/security.svg"
                                    data={securityFeatures}
                                />
                                <Spacing md="70" lg="50" />
                                <List
                                    heading={agileHeading}
                                    iconUrl="/images/icons/agile-dev.svg"
                                    data={agileFeatures}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <Section topMd={50} topLg={100} topXl={110}>
                <DepartmentSection
                    sectionTitle="Our Departments"
                    sectionTitleUp="MORE TYPE OF"
                    bgUrl="images/home_1/department_bg.svg"
                    data={departmentData}
                />
            </Section>

            {/* Start Appointment Section */}
            <Section
                topMd={190}
                topLg={145}
                topXl={105}
                bottomMd={190}
                bottomLg={145}
                bottomXl={110}
                id="appointment"
            >
                <AppointmentSection
                    sectionTitle="Consultation"
                    sectionTitleUp="SCHEDULE A"
                    imgUrl="/images/home_1/appointment.jpeg"
                />
            </Section>
        </>
    );
}