import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <Link  to="https://www.upwork.com/freelancers/~010b88d29e81c731e8" target="_blank">
          <Icon icon="cib:upwork" />
        </Link>
        <Link to="https://mostaql.com/u/screencraft" target="_blank">
          <Icon icon="simple-icons:freelancer" />
        </Link>
      </div>
    </div>
  );
}
