import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function AppointmentForm() {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    objects: '',
    description: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
/*
      // Send the form data to the backend
      const response = await fetch('http://localhost:5000/send-to-telegram', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Form submitted successfully!');
        setFormData({ name: '', phoneNumber: '', objects: '', description: '' }); // Clear the form
      } else {
        alert('Failed to submit the form.');
      }

*/

      // Construct the message
      const message = `
      *New Project Request:* \n
      Name: ${formData.name || 'Not provided'} \n
      Phone Number: ${formData.phoneNumber || 'Not provided'} \n
      Objects: ${formData.objects || 'Not provided'} \n
      Description: ${formData.description || 'Not provided'} \n
    `.trim();

      // Encode the message for the URL
      const encodedMessage = encodeURIComponent(message);

      // Replace YOUR_BOT_USERNAME with your bot's username
      const telegramUrl = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=5649232854&text=${encodedMessage}`;
      const telegramUrl2 = `https://api.telegram.org/bot6480198259:AAGKS2vf07Rhy5wKRLy9DsxM6ohkNa0B40s/sendMessage?chat_id=6534547018&text=${encodedMessage}`;

      await fetch(telegramUrl)
      await fetch(telegramUrl2)

      setFormData({ name: '', phoneNumber: '', objects: '', description: '' }); // Clear the form


    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form.');
    }
  };
   return (
       <form onSubmit={handleSubmit} className="row">
         <div className="col-lg-6">
           <label className="cs_input_label cs_heading_color">Name</label>
           <input
               type="text"
               className="cs_form_field"
               placeholder="David John"
               name="name"
               value={formData.name}
               onChange={handleChange}
           />
           <div className="cs_height_42 cs_height_xl_25"/>
         </div>
         <div className="col-lg-6">
           <label className="cs_input_label cs_heading_color">Phone Number</label>
           <input
               type="text"
               className="cs_form_field"
               placeholder="(123) 456 - 789"
               name="phoneNumber"
               value={formData.phoneNumber}
               onChange={handleChange}
           />
           <div className="cs_height_42 cs_height_xl_25"/>
         </div>
         <div className="col-lg-12">
           <label className="cs_input_label cs_heading_color">Objects</label>
           <textarea
               className="cs_form_field"
               placeholder="Object title"
               name="objects"
               value={formData.objects}
               onChange={handleChange}
           />
           <div className="cs_height_42 cs_height_xl_25"/>
         </div>
         <div className="col-lg-12">
           <label className="cs_input_label cs_heading_color">Description</label>
           <textarea
               className="cs_form_field"
               placeholder="Fast Description details"
               name="description"
               value={formData.description}
               onChange={handleChange}
           />
           <div className="cs_height_42 cs_height_xl_25"/>
         </div>

         {/*
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Department</label>
          <div className="cs_radio_group">
            <div className="cs_radio_wrap">
              <input
                  className="cs_radio_input"
                  type="radio"
                  name="department"
                  id="pediatric"
                  defaultValue="pediatric"
              />
              <label className="cs_radio_label" htmlFor="pediatric">
                Pediatric
              </label>
            </div>
            <div className="cs_radio_wrap">
              <input
                  className="cs_radio_input"
                  type="radio"
                  name="department"
                  id="obstetricsGynecology"
                  defaultValue="obstetricsGynecology"
                  defaultChecked=""
              />
              <label className="cs_radio_label" htmlFor="obstetricsGynecology">
                Obstetrics and Gynecology
              </label>
            </div>
            <div className="cs_radio_wrap">
              <input
                  className="cs_radio_input"
                  type="radio"
                  name="department"
                  id="cardiology"
                  defaultValue="cardiology"
              />
              <label className="cs_radio_label" htmlFor="cardiology">
                Cardiology
              </label>
            </div>
            <div className="cs_radio_wrap">
              <input
                  className="cs_radio_input"
                  type="radio"
                  name="department"
                  id="neurology"
                  defaultValue="neurology"
              />
              <label className="cs_radio_label" htmlFor="neurology">
                Neurology
              </label>
            </div>
          </div>
          <div className="cs_height_42 cs_height_xl_25"/>
        </div>
*/}
         <div className="col-lg-12">
           <button type="submit" className="cs_btn cs_style_1">
             <span>Submit</span>
             <i>
               <img src="/images/icons/arrow_white.svg" alt="Icon"/>
               <img src="/images/icons/arrow_white.svg" alt="Icon"/>
             </i>
           </button>
         </div>
       </form>
   );
}
